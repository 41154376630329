import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {Document, Outline, Page, pdfjs} from 'react-pdf';
import {baseUrl, getUriPart, isLogin, toBase64} from "../utils";
import {useSelector} from "react-redux";
import {tokenData} from "../store/reducers/tokenReducer";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { PDFDocumentProxy } from 'pdfjs-dist';
import {useResizeObserver} from "@wojtekmaj/react-hooks";
import '../CSS/Read.css';
import {FaHome, FaList, FaMinus, FaPager, FaPause, FaPlay, FaPlus, FaWindowClose} from "react-icons/fa";
import {FaBackwardStep, FaForwardStep, FaMaximize} from "react-icons/fa6";
import useWindowDimensions from "../Hooks/device";

// pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.mini.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs',//'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

// console.log(`cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`)

const resizeObserverOptions = {};

const maxWidth = 700;
const maxHeight = 500;

export default function Read(){
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const hasToken = useSelector(tokenData);
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();
    const [isFull, setFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [isListView, setListView] = useState(false);
    const [isPage, setIsPage] = useState(false);


    const options = useMemo(() => ({
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
        httpHeaders: {
            authorization: "Bearer "+hasToken,
        }
    }), [hasToken]);

    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);

    const key = process.env.REACT_APP_FEE;

    const file = baseUrl()+"/api/readerer/"+toBase64(getUriPart(2)+':'+hasToken);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy) => {
        setNumPages(nextNumPages);
    }

    const onPassword = (callback, reason) =>{
        function callbackProxy(password) {
            // Cancel button handler
            if (password === null) {
                // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
            }

            callback(password);
        }

        switch (reason) {
            case 1: {
                callbackProxy(key);
                break;
            }
            case 2: {
                callbackProxy(key);
                break;
            }
            default:
        }
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const onFullScreen = (event)=>{
        let reader = document.querySelector('.pdf-viewer');
        let element = document.querySelector('.Pdf-viewer__container');
        if (!element) return;

        if (isFull) {
            // If document is currently in full screen mode, exit full screen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { // Safari
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE11
                document.msExitFullscreen();
            }
            setFullScreen(false);
        } else {
            // If document is not in full screen mode, request full screen for the element

            if (reader.requestFullscreen) {
                reader.requestFullscreen();
            } else if (reader.webkitRequestFullscreen) { // Safari
                reader.webkitRequestFullscreen();
            } else if (reader.msRequestFullscreen) { // IE11
                reader.msRequestFullscreen();
            }

            setFullScreen(true);
        }

        let transform = 'scale(1)';
        element.style.webkitTransform = transform;
        element.style.mozTransform = transform;
        element.style.transform = transform;
    }

    const  abortPrint = function (){
        alert("Printing is not allowed");
        document.write();
    }

    const zoomIn = function (ev) {
        setScale(scale + .2);
    }

    const zoomOut = function () {
        setScale(1);
    }

    const mouseWheelHandler = (ev) => {

        let e = window.event || ev; // old IE support
        let el = document.querySelector('.Pdf-viewer__container');

        if (!el) return;

        let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        let oldScale = scale;

        setScale(scale + (delta / 10));

        if (scale < 1) setScale(1);
        if (scale > 7) setScale(7);

        let parentRect = el.parentNode.getBoundingClientRect();
        let rect = el.getBoundingClientRect();
        let mouseX = e.pageX - parentRect.left;
        let mouseY = e.pageY - parentRect.top;

        // Calculate the new position to keep the mouse cursor position fixed after zooming
        let newWidth = rect.width * scale / oldScale;
        let newHeight = rect.height * scale / oldScale;
        let left = mouseX - (mouseX / rect.width) * newWidth;
        let top = mouseY - (mouseY / rect.height) * newHeight;

        // Adjust left and top to ensure that the content remains within the viewport
        if (left > 0) left = 0;
        if (top > 0) top = 0;
        if (left + newWidth < parentRect.width) left = parentRect.width - newWidth;
        if (top + newHeight < parentRect.height) top = parentRect.height - newHeight;

        setScale(scale);

        // let transform = 'scale(' + scale + ') translate(' + left + 'px,' + top + 'px)';
        // el.style.webkitTransform = transform;
        // el.style.mozTransform = transform;
        // el.style.transform = transform;
    }

    let c = pageNumber;
    const onPlay = ()=>{
        // if (!isPlaying) {
        //     setPaying(true);
        //     setTimeout(() => {
        //
        //         if(c <= 1 && c !== pages) {
        //
        //             // console.log(c);
        //             book.current.pageFlip().flip(c);
        //         }else{
        //             if(c > 0 && c <= pages) {
        //                 book.current.pageFlip().flip(c);
        //             }else{
        //                 c = 0;
        //                 book.current.pageFlip().flip(0);
        //             }
        //         }
        //
        //         onPlay();
        //         c++;
        //     }, 5000);
        //
        // } else {
        //     setPlaying(false);
        // }
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    const goTo = (page)=>{
        if(isPage){
            setIsPage(false)
        }else{
            setIsPage(true)
        }
    }

    useEffect(() => {

        document.oncontextmenu = function() {
            return false;
        }

        function disableselect(e) {return false}

        let referrer = document.referrer;

        if(!referrer.includes("apps.mwanaclick.com")){
            document.onselectstart = disableselect;
            document.onmousedown = disableselect;

            document.body.addEventListener("cut copy paste print",function (e){
                e.preventDefault();
                return abortPrint();
            });

            let c = document.querySelector("canvas");
            if(c){
                c.style.margin = "0px !important"
            }
        }

        if (!isLogin() && !referrer.includes("mwanaclick.com") && !referrer.includes("localhost")){
            // console.log(referrer.includes("localhost"))
            return  window.location.href = '/';
        }

        document.addEventListener("keydown",function(event) {
            // mouseWheelHandler(event);
            if (event.key === 'PrintScreen' || (event.key === 'Insert' && event.altKey)) {
                abortPrint();
                return event.preventDefault();
                // Handle the screenshot attempt
            }else
            if (event.ctrlKey===true && (event.code === '118' || event.code === '86')) {
                abortPrint();
                return  event.preventDefault();
            }
            else if (event.code === 123) {
                abortPrint();
                return false;
            }
            else if (event.ctrlKey && (event.code === 85 || event.code === 83 || event.code ===65 )) {
                abortPrint();
                return false;
            }
            else if (event.ctrlKey && event.shiftKey && event.code === 73)
            {
                abortPrint();
                return false;
            }
            else if(event.ctrlKey && event.code===80){
                abortPrint();
                return false;
            }
        });

    }, []);
    
    const ListView = ()=>{
        if(isListView){
            setListView(false);
        }else{
            setListView(true)
        }
    }

    return (
        <div className="pdf-viewer">
            <div className="Pdf-viewer__container" ref={setContainerRef}>

                <Document file={file} onPassword={onPassword} onLoadSuccess={onDocumentLoadSuccess} onMousewheel={(ev)=>mouseWheelHandler(ev)} renderMode={"svg"} options={options}
                          loading={<div className="loader-container"><span className="loader"></span></div>}>
                    {/*<Outline onItemClick={onItemClick} />*/}
                    {isListView && numPages != null && Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page pageNumber={index + 1}
                                  key={`page_${index + 1}`}
                                  scale={scale}
                                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                                  loading={<div className="loader-container"><span className="loader"></span></div>}/>
                        ),
                    )}
                    {!isListView && (
                        <Page pageNumber={pageNumber}
                              key={`page_${pageNumber}`}
                              scale={scale}
                              width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                              loading={<div className="loader-container"><span className="loader"></span></div>}/>
                    )}

                </Document>
            </div>

            <div className="navbar bg-light fixed-bottom ">
                <div className="container">
                    <ul className={"reader-navigation"}>
                        <li className={"home"}>
                            <Link to={"/"}>
                                <FaHome/>
                            </Link>
                        </li>
                        <li className={"zoom-in"} onClick={zoomIn}>
                            <Link className={"zoom-in"}  to={""}>
                                <FaPlus className={"zoom-in"}/>
                            </Link>
                        </li>
                        <li className={"zoom-out"} onClick={zoomOut}>
                            <Link className={"zoom-out"} style={{color: scale <= 1 ? "rgba(100,100,100,.2)" : "var(--primary-color)" }} to={""}>
                                <FaMinus className={"zoom-out"}/>
                            </Link>
                        </li>
                        {!isListView && (
                            <>
                                <li className={"back"} onClick={pageNumber <= 1 ? null : previousPage}>
                                    <Link className={"back"}
                                          style={{color: pageNumber <= 1 ? "rgba(100,100,100,.2)" : "var(--primary-color)"}}
                                          to={""}>
                                        <FaBackwardStep className={"back"}/>
                                    </Link>
                                </li>

                                <li className={"pages"}>
                                    <input className="page-nums-input" style={{
                                        width: "60px",
                                        color: "var(--primary-color)",
                                        border: "1px solid rgba(51,108,240,.2)",
                                        padding: "2px 10px"
                                    }}
                                          value={`${pageNumber}/${numPages}`} onChange={goTo}/>
                                </li>

                                <li className={"next-pc"} onClick={pageNumber >= numPages ? null : nextPage}>
                                    <Link className={"next-page"}
                                          style={{color: pageNumber >= numPages ? "rgba(100,100,100,.2)" : "var(--primary-color)"}}
                                          to={""}>
                                        <FaForwardStep className={"next"}/>
                                    </Link>
                                </li>
                            </>
                        )}


                        <li className={"list-pages"} onClick={ListView}>
                            <Link className={"list-page"} to={""}>
                                {!isListView ? (
                                    <FaList className={"list"}/>
                                ) : (
                                    <FaPager className={"list"}/>
                                )}
                            </Link>
                        </li>

                        {/*<li className={"can-play"} onClick={onPlay}>*/}
                        {/*    <a className={"can-play"}>*/}
                        {/*        {isPlaying ? (*/}
                        {/*            <FaPause className={"play"}/>*/}
                        {/*        ) : (*/}
                        {/*            <FaPlay className={"pause"}/>*/}
                        {/*        )}*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li className={"expand"} onClick={onFullScreen}>
                            <a className={"expand"}>
                                {isFull ? (
                                    <FaWindowClose className={"exit"} style={{color:"red"}} title={"Exit"}/>
                                ) : (
                                    <FaMaximize className={"expand"} title={"Expand"}/>
                                )}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}